<template>
  <div>
    <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
      <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">領獎紀錄</h4>
      <div
        class="
          col-12 col-xl-6
          d-flex
          flex-xl-row
          align-items-start align-items-xl-center
          justify-content-end
        "
      >
        <b-button
          class="flex-shrink-0 mb-0 ml-2 btn mr-3"
          variant="primary"
          @click="handleExport"
          v-if="query.merchant_id"
        >
          <i class="fa fa-plus"></i>匯出 excel
        </b-button>
        <template v-if="showFilter">
          <b-form-select
            v-model="query.merchant_id"
            :options="merchants"
            :disabled="merchants.length == 2"
            @change="handlePageReset"
          ></b-form-select>
        </template>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      :items="redeemLogs"
      :fields="fields"
      :busy="isLoading"
      @sort-changed="handleSort"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(customer_name)="data">
        {{ data.item.customer_name | hiddenString(20) }}
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
      </template>
    </b-table>

    <CustomPagination
      :currentPage="query.page"
      :totalRows="total"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointLogApi from "@/apis/point-log";
import merchantApi from "@/apis/merchant";
import store from "@/store";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import * as consts from "@/consts";

export default {
  components: {
    CustomPagination,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    includeFields: {
      type: Array,
      default: () => [],
    },
    upperMerchantId: {
      type: String,
      default: null,
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      redeemLogs: [],
      merchants: [{ value: null, text: "請選擇" }],
      currentPage: 1,
      total: 0,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        sort_by: "created_at",
        order_by: "desc",
        merchant_id: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
    fields() {
      let fields = [
        {
          key: "created_at",
          label: "兌換時間",
          sortable: true,
        },
        {
          key: "gift_name",
          label: "獎項名稱",
        },
        {
          key: "gift_data",
          label: "獎項參數",
        },
        {
          key: "batch_total_points",
          label: "使用點數",
        },
        {
          key: "customer_name",
          label: "LINE 名稱",
        },
        {
          key: "customer_profile_name",
          label: "會員姓名",
        },
        {
          key: "customer_mobile_phone",
          label: "手機",
        },
        {
          key: "customer_email",
          label: "Email",
        },
        {
          key: "customer_county",
          label: "縣市",
        },
        {
          key: "customer_district",
          label: "區域",
        },
        {
          key: "customer_address",
          label: "地址",
        },
      ];

      if (this.includeFields.length > 0) {
        fields = fields.filter((field) =>
          this.includeFields.includes(field.key)
        );
      }

      return fields;
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  mounted() {
    this.fetchMerchants();
    this.initialized = true;
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    "$route.query": {
      handler(newQuery) {
        if (!this.initialized) return;
        if (Object.keys(newQuery).length !== 0) {
          this.query = { ...newQuery };
        }
        this.fetchRedeemLogs();
      },
      deep: true,
    },
  },
  methods: {
    async fetchRedeemLogs() {
      try {
        this.isLoading = true;
        const { data } = await pointLogApi.getRedeemLogs(
          this.upperMerchantId || this.query.merchant_id,
          {
            // 若 upperCustomerId 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
            customer_id: this.upperCustomerId || "",
            ...this.query,
          }
        );
        this.total = data.meta.total;
        this.redeemLogs = data.data.map((log) => ({
          ...log,
          customer_profile_name: log.customer_profiles.name,
          customer_county: log.customer_profiles.county,
          customer_district: log.customer_profiles.district,
          customer_address: log.customer_profiles.address,
        }));
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleExport() {
      if (this.query.merchant_id) {
        const searchParams = new URLSearchParams({
          sort_by: this.query.sort_by,
          order_by: this.query.order_by,
          token: store.state.auth.token,
          // 若 upperCustomerId 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
          customer_id: this.upperCustomerId || "",
        });
        const url = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}/merchants/${this.query.merchant_id}/redeem-logs/export?${searchParams}`;

        window.open(url);
      }
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        const defaultMerchant = data.data.find((merchant) => merchant.type === this.defaultMerchantType) ??
          data.data.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE) ;

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        await this.fetchRedeemLogs();
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>
